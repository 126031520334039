import React, { memo } from "react";
import { Table } from "@mantine/core";
import NoData from "../../../no-data/NoData";

const DynamicTable: React.FC<TTableListView> = ({ data = [], columns }) => {
  const statusColors: { [key: string]: string } = {
    absent: "#FF0000",
    late: "#f79009",
  };

  if (data.length === 0) {
    return <NoData title="No Data Found" />;
  }
  return (
    <Table horizontalSpacing="xl" verticalSpacing="md" highlightOnHover h={60}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.key}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row: any) => {
          if (!row["status"]) {
            return (
              <tr key={row._id}>
                {columns.map((column) => {
                  return (
                    <td key={column.key}>
                      {column.renderCell
                        ? column.renderCell(row)
                        : row[column.key]}
                    </td>
                  );
                })}
              </tr>
            );
          }

          return (
            <tr key={row._id}>
              {columns.map((column) => {
                const bgcolor = statusColors[row["status"]];
                const textColor = bgcolor ? "white" : "black";
                const style = {
                  backgroundColor: bgcolor,
                  color: textColor,
                };
                if (column.key === "status") {
                  return (
                    <td
                      key={column.key}
                      style={{ ...style, textTransform: "capitalize" }}
                    >
                      {row[column.key]}
                    </td>
                  );
                }
                if (column.key === "inTime" || column.key === "outTime") {
                  if (!row[column.key]) {
                    return (
                      <td key={column.key} style={style}>
                        NA
                      </td>
                    );
                  }
                  return (
                    <td key={column.key} style={style}>
                      {new Date(row[column.key]).toLocaleTimeString()}
                    </td>
                  );
                } else if (
                  column.key === "inNote" ||
                  column.key === "outNote"
                ) {
                  if (!row[column.key] || row[column.key] === "") {
                    return (
                      <td key={column.key} style={style}>
                        NA
                      </td>
                    );
                  }
                  return (
                    <td key={column.key} style={style}>
                      {row[column.key]}
                    </td>
                  );
                }
                return (
                  <td key={column.key} style={style}>
                    {column.renderCell
                      ? column.renderCell(row)
                      : row[column.key]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default memo(DynamicTable);
