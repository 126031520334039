import { authUrls } from "./auth.url";
import { studentUrls } from "./student.url";
import { attendanceUrls } from "./attendance.url";
import { settingsUrl } from "./settings.url";
import { qrUserUrls } from "./qruser";
import {holidayUrls} from "./holiday.url"
 
export const apiUrls = {
  ...authUrls,
  ...studentUrls,
  ...attendanceUrls,
  ...settingsUrl,
  ...qrUserUrls,
  ...holidayUrls
};
