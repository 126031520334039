import { IconHome, IconUsers, IconCalendar, IconCalendarOff,IconNotes } from "@tabler/icons-react";

const navData = [
  { link: "/", label: "Dashboard", icon: IconHome },
  { link: "/employees", label: "Employees", icon: IconUsers },
  {link: "/attendance", label: "Attendance", icon: IconCalendar},
  {link: "/holidays", label: "Holidays", icon: IconCalendarOff},
  {link: "/notes", label: "Notes", icon: IconNotes},
];

export default navData;
