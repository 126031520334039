import { showNotification } from "@mantine/notifications";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { deleteAllCookies } from "./getCookies.service";

const client = axios.create({ baseURL: `${process.env.REACT_APP_BASE_URL}` });

export const request = async (options: AxiosRequestConfig<unknown>) => {
  const token = localStorage.getItem("token");
  client.defaults.headers.common.authorization = `Bearer ${token}`;

  const onSuccess = (response: AxiosResponse) => response.data;
  const onError = (error: TErrorData) => {
    if (error.errorCode === 401) {
      deleteAllCookies();
      window.location.replace("/login");
      showNotification({
        message: "Session expired please login again",
        color: "red",
      });
    } else if (error.errorCode && error.errorCode >= 500) {
      showNotification({
        title: error.errorTitle,
        message: error.errorMessage,
        color: "red",
      });
    }

    return { title: error.errorTitle, message: error.errorMessage, data: [] };
  };

  try {
    const response = await client(options); 
    return onSuccess(response);
  } catch (error) {
    return onError(error as TErrorData);
  }
};
